
body {
  background: linear-gradient(178.6deg, rgb(20, 36, 50) 11.8%, rgb(124, 143, 161) 83.8%);
}

.nav-menu {
  background: linear-gradient(178.6deg, rgb(20, 36, 50) 11.8%, rgb(124, 143, 161) 83.8%) !important;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 10px 15px;

}

#about, #portfolio, #certificate, #skills, #contact {
  scroll-margin: 4em;
}

.resume-btn {
  background-color: grey;
  border-radius: 25px;
  border: black;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 10px 15px;

}
.resume-btn:hover {
  background-color: black;
  border: 1px solid white;
  transform: translate(0px, -3px);
}

section#home.hero {
  background-image: url('../../public/images/hero.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode:lighten;
  height: 700px;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;

}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: whitesmoke;
  font-weight: 700;
  font-size: large;
}

.profile-img {
  border-radius: 25px;
  margin-bottom: 60px;
  border: solid grey;
  border-width: 2px;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
}

h1, h2, h3, h5, p {
  text-align: center;
  color: whitesmoke;
}

.port-title {
  text-align: center;
}

.portfolio-card {
  background-color: lightgray;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 20px 40px;

}


.img-fluid {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 3px;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;
  
}

.image.img-fluid {
  object-fit: contain;
  border-radius: 5px;

}

.card {
  background: rgb(36,126,177);
  background: radial-gradient(circle, rgba(36,126,177,1) 28%, rgba(63,69,125,1) 100%, rgba(82,174,196,1) 100%, rgba(63,60,136,1) 100%);
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;
}

.flip-card1 {
  background-color: transparent;
  width: 250px;
  height: 300px;
  perspective: 1000px;
  margin: 12%;
  margin-left: 16%;
}

.flip-card2 {
  background-color: transparent;
  width: 250px;
  height: 300px;
  perspective: 1000px;
  margin: 12%;
  margin-left: 16%;
}

.flip-card3 {
  background-color: transparent;
  width: 250px;
  height: 300px;
  perspective: 1000px;
  margin: 12%;
  margin-left: 16%;
}

.flip-card1-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card2-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card3-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card1:hover .flip-card1-inner {
  transform: rotateY(180deg);
}

.flip-card2:hover .flip-card2-inner {
  transform: rotateY(180deg);
}

.flip-card3:hover .flip-card3-inner {
  transform: rotateY(180deg);
}

.flip-card1-front, .flip-card1-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card2-front, .flip-card2-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card3-front, .flip-card3-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card1-front  {
  background: rgb(36,126,177);
  background: radial-gradient(circle, rgba(36,126,177,1) 28%, rgba(63,69,125,1) 100%, rgba(82,174,196,1) 100%, rgba(63,60,136,1) 100%);
  border-radius: 5px;
  color: black;
  padding: 25px;
  padding-top: 25%;
}

.flip-card2-front  {
  background: rgb(36,126,177);
  background: radial-gradient(circle, rgba(36,126,177,1) 28%, rgba(63,69,125,1) 100%, rgba(82,174,196,1) 100%, rgba(63,60,136,1) 100%);
  border-radius: 5px;
  color: black;
  padding: 25px;
  padding-top: 25%;
}

.flip-card3-front  {
  background: rgb(36,126,177);
  background: radial-gradient(circle, rgba(36,126,177,1) 28%, rgba(63,69,125,1) 100%, rgba(82,174,196,1) 100%, rgba(63,60,136,1) 100%);
  border-radius: 5px;
  color: black;
  padding: 25px;
  padding-top: 25%;
}

.flip-card1-back {
  transform: rotateY(180deg);
}

.flip-card2-back {
  transform: rotateY(180deg);
}

.flip-card3-back {
  transform: rotateY(180deg);
}

.flip-card1-back img {
  width: 250px;
  height: 300px;
}

.flip-card2-back img {
  width: 250px;
  height: 300px;
}

.flip-card3-back img {
  width: 250px;
  height: 300px;
}

.btn-about {
  background-color: #1e90ff;
  border-radius: 25px;
  border: black;
  padding: 12px;
  width: 150px;
  margin-top: 10px;

}

.btn-about:hover {
  background-color: blue;
  border: 1px solid white;
  transform: translate(0px, -3px);
}

.btn-footer {
  background-color: grey;
  border: solid grey;
  border-radius: 25px;
  padding: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
}

.btn-footer:hover {
  background-color: grey;
  border: 1px solid lightgray;
  transform: translate(0px, -3px);
}


.three-items {
  text-align: center;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 0;
}


.about-text {
  margin: auto;
}

.about-card {
  margin: 12%;
  padding: 5%;
  border-radius: 8px;
}


.about-section {
  margin-top: 0;
  margin-bottom: 100px;
}

.portfolio-col {
  margin-bottom: 50px;
}

.cert-col {
  margin-bottom: 50px;
}

img.image.img-fluid {
  object-fit: cover;
  width: 300px;
  height: 225px;
  margin-left: 11%;

}

.portfolio-text {
  margin-bottom: 100px; 
}


a:link, 
a:visited {
  color: whitesmoke;
  text-decoration: none;
}
.a:hover { 
  text-decoration:none;
}

.title-modal {
  color: black;
  font-weight: 700;
}

.content-modal {
  color: black;
  font-weight: 700;
}


.skills-text {
  margin-bottom: 100px;
  margin-top: 150px;
}


.cert-text {
  margin-bottom: 100px;
  margin-top: 150px;
}



.slider {
  align-items: center;
	background: #E3E3E3;
	display: flex;
	height: 100vh;
	justify-content: center;

}

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 50s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.slider-container {
	align-items: center;
	display: flex;
	justify-content: center;
}

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

// Styling
.slider {
	background: black;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 1100px;
  min-width: none;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;
  border-radius: 3px;

  
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 80px;
		width: 250px;
	}
}


div.git-icon.col {
  text-align: right;
  margin-top: 25px;
}

div.git-icon.col img {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;
}



.linkedin-icon {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
}

.linkedin-icon img {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  box-shadow: rgba(1, 1, 1, 0.35) 0px 15px 25px;
  
}

.contact-email {
  margin-top: 60px; 
}



